import { useCreateFinancingPerQuotationFormContext } from '../../hooks/useCreateFinancingPerQuotationForm'
import { MaskedMonetaryInput } from 'components/Controllers/MaskedMonetaryInput'
import * as S from './styles'
import { SelectGraceDays } from '../ui/SelectGraceDays'
import { useEffect } from 'react'
import { type IQuotationDTO } from 'dto/QuotationDTO'

interface PaymentProps {
  choosedQuotation: IQuotationDTO
}

export function Payment({ choosedQuotation }: PaymentProps) {
  const {
    control,
    formState: { errors },
    setValue,
  } = useCreateFinancingPerQuotationFormContext()

  useEffect(() => {
    setValue('equipments_value', choosedQuotation.equipment_value)
    setValue('labor_value', choosedQuotation.extra_costs)
    setValue('financing_value', choosedQuotation.total_value)
  }, [])

  return (
    <S.Container>
      <section>
        <h3>Informações de pagamento</h3>
        <SelectGraceDays
          control={control}
          label="Dias de Carência"
          placeholder="Selecione os dias de Carência"
          name="grace_days"
          error={errors.grace_days?.message}
        />
      </section>

      <section>
        <h3>Simulação de financiamento</h3>
        <S.InputGroup>
          <MaskedMonetaryInput
            label="Valor dos Equipamentos"
            placeholder="R$0,00"
            name="equipments_value"
            control={control}
            disabled
          />

          <MaskedMonetaryInput
            label="Valor Financiado"
            placeholder="R$0,00"
            name="financing_value"
            disabled
          />
        </S.InputGroup>
      </section>
    </S.Container>
  )
}
