import { type Resolver, useForm, useFormContext } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import {
  type DefaultValuesForm,
  type ICreateFinancingPerValueFormData,
} from '../types'
import { schemaByPersonType } from '../validators/formValidator'
import { useIsAdmin } from 'hooks/useIsAdmin'

export function useCreateFinancingPerValueForm(
  defaultValues: DefaultValuesForm,
) {
  const [personType, setPersonType] = useState(defaultValues.client_type)

  const isAdmin = useIsAdmin()

  const schema = useMemo(() => {
    if (isAdmin) {
      return schemaByPersonType.admin[personType]
    }
    return schemaByPersonType.base[personType]
  }, [isAdmin, personType])

  const methods = useForm<ICreateFinancingPerValueFormData>({
    resolver: yupResolver(schema) as Resolver<ICreateFinancingPerValueFormData>,
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })
  const clientType = methods.getValues('client_type')
  useEffect(() => {
    setPersonType(clientType)
  }, [clientType])
  return methods
}

export const useCreateFinancingPerValueFormContext = () => {
  return useFormContext<ICreateFinancingPerValueFormData>()
}
