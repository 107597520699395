import { useCallback, useState } from 'react'
import {
  type DefaultValuesForm,
  type ICreateFinancingPerValueFormData,
} from '../components/FormManager/types'
// import { useUserHasFullRegistration } from 'hooks/useUserHasFullRegistration'
import { useFinancingCreateSimulation } from 'domains/financing/create-simulation'
import { type INewFinancingSimulationPOSTPayload } from 'domains/financing/create-simulation/services/types'
import { useCreateFinancingPerValueModalContext } from '../context/CreateFinancingPerValueModalContext'
import { useSuccessFinancingRequestModalContext } from 'context/modals/financing/SuccessFinancingRequestModalContext'
import { useAmplitude } from 'hooks/useAmplitude'
import { financingTracker } from 'services/tracker/events/financing/trackers'
import { format } from 'date-fns'
import { FINANCING_TYPE, INICIAL_VALUES_FORM } from '../constants'
import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'
import { FINANCING_REQUESTS_ENDPOINT_KEY } from 'domains/financing/financing-requests/constants'
import { useByPassSaveProposal } from 'layouts/Dashboard/hooks/useByPassSaveProposal'
import { calculateClientYearsOld } from 'containers/Financing/helpers/calculateClientYearsOld'
import { INSURANCE_YEARS_OLD_LIMIT } from 'containers/Financing/constants'
import { queryClient } from 'services/queryClient'
import { DASHBOARD_FINANCING_REQUESTS } from 'domains/home/dashboard-financing-requests/constants/endpointKeys'

export function useCreateFinancingPerValueModal() {
  const handleRefreshFinancingListAtHome = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [DASHBOARD_FINANCING_REQUESTS],
      exact: false,
      refetchType: 'active',
    })
  }, [])

  const { mutateAsync, isError, isLoading } = useFinancingCreateSimulation({
    onSuccess: () => {
      handleRefreshFinancingListAtHome()
    },
  })
  // const { validateUserRegistration } = useUserHasFullRegistration()

  const [requestFallback, setRequestFallback] = useState<
    DefaultValuesForm | ICreateFinancingPerValueFormData
  >(INICIAL_VALUES_FORM)

  const { setFinancingPerValueModalClose } =
    useCreateFinancingPerValueModalContext()
  const { setSuccessFinancingModalOpen } =
    useSuccessFinancingRequestModalContext()

  const { logEvent } = useAmplitude()
  const { handleByPassSaveProposal } = useByPassSaveProposal()

  const manageRequestFallback = useCallback(
    (data: ICreateFinancingPerValueFormData) => {
      const { client_birth_date, ...rest } = data

      setRequestFallback({
        ...rest,
        client_birth_date: format(client_birth_date as Date, 'dd-MM-yyyy'),
      })
    },
    [],
  )

  const handleCreateFinancingByValueRequest = useCallback(
    async (data: ICreateFinancingPerValueFormData) => {
      manageRequestFallback(data)

      const {
        client_birth_date,
        integrator,
        client_type,
        client_income,
        equipments_value,
        labor_value,
        is_insurance = false,
        ...rest
      } = data

      const clientType =
        client_type === PERSON_TYPE_ENUM.NATURAL
          ? 'Pessoa Física'
          : 'Pessoa Jurídica'

      logEvent(financingTracker.actions.financingCreated, {
        financingType: 'Valor',
        clientType,
      })

      // if (!validateUserRegistration()) return

      const { yearsOld: clientYearsOld } = calculateClientYearsOld(
        format(client_birth_date as Date, 'dd/MM/yyyy'),
      )

      const payload = {
        ...rest,
        client_type,
        client_birth_date: format(client_birth_date as Date, 'yyyy-MM-dd'),
        origin: FINANCING_TYPE,
        client_income: client_income.toFixed(2),
        equipments_value: equipments_value.toFixed(2),
        labor_value: labor_value.toFixed(2),
        ...(data.integrator?.id && {
          user_id: Number(data.integrator?.id),
        }),
        ...(client_type === PERSON_TYPE_ENUM.NATURAL && {
          is_insurance,
        }),
        ...(clientYearsOld > INSURANCE_YEARS_OLD_LIMIT && {
          is_insurance: false,
        }),
      }

      mutateAsync(payload as INewFinancingSimulationPOSTPayload).then(
        async ({ origin_id }) => {
          setFinancingPerValueModalClose()
          setSuccessFinancingModalOpen(true, origin_id)
          await queryClient.invalidateQueries({
            queryKey: [FINANCING_REQUESTS_ENDPOINT_KEY],
            exact: false,
          })
        },
      )
    },
    [
      manageRequestFallback,
      logEvent,
      mutateAsync,
      handleByPassSaveProposal,
      setFinancingPerValueModalClose,
      setSuccessFinancingModalOpen,
    ],
  )

  return {
    isError,
    isLoading,
    handleCreateFinancingByValueRequest,
    requestFallback,
  }
}
