import { Radio } from '@77sol-ui/atoms'
import { useCreateFinancingPerValueFormContext } from '../../../hooks/useCreateFinancingPerValueForm'
import { type PersonType } from '../../../types'
import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'

export const RadioPersonType = () => {
  const {
    register,
    formState: { errors },
    setValue,
    reset,
    watch,
    getValues,
  } = useCreateFinancingPerValueFormContext()

  const choosedPersonType = watch('client_type')

  const onValueChange = (value: PersonType) => {
    reset({
      title: getValues('title'),
      equipments_value: getValues('equipments_value'),
      labor_value: getValues('labor_value'),
      client_name: '',
      client_birth_date: '',
      client_document: '',
      client_income: 0.0,
      grace_days: '90',
    })
    setValue('client_type', value)
  }

  return (
    <Radio.Root>
      <Radio.Group
        iconType="success"
        {...(register('client_type'),
        {
          error: Boolean(errors.client_type?.message),
          onValueChange,
          defaultValue: choosedPersonType,
        })}
      >
        <Radio.Item
          name={PERSON_TYPE_ENUM.NATURAL}
          value={PERSON_TYPE_ENUM.NATURAL}
        >
          Pessoa Física
        </Radio.Item>
        <Radio.Item
          name={PERSON_TYPE_ENUM.JURIDICAL}
          value={PERSON_TYPE_ENUM.JURIDICAL}
        >
          Pessoa Jurídica
        </Radio.Item>
      </Radio.Group>
    </Radio.Root>
  )
}
