import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'

export const FINANCING_TYPE = 'value'

export const INICIAL_VALUES_FORM = {
  client_type: PERSON_TYPE_ENUM.NATURAL,
  grace_days: '90',
  equipments_value: 0.0,
  labor_value: 0.0,
}
