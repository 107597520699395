import * as yup from 'yup'
import { type PersonType } from '../types'
import { parse } from 'date-fns'
import { PERSON_TYPE_ENUM } from 'enums/PersonTypeEnum'

const PERCENT_LIMIT_INPUT_VALUE = 0.9
const PERCENT_LIMIT_INPUT_VALUE_LABEL = PERCENT_LIMIT_INPUT_VALUE * 100

const formatDateToValid = (originalValue: string) => {
  if (originalValue.length < 10) return
  const parsedDate = parse(originalValue, 'dd/MM/yyyy', new Date())
  if (parsedDate < new Date()) return parsedDate
}

export const baseSchemaJuridicalPerson = yup.object().shape({
  client_type: yup.string().required(),
  client_name: yup
    .string()
    .required('Insira o nome do solicitante.')
    .min(4, 'O campo precisa ter no mínimo 4 caracteres.'),
  client_birth_date: yup.date().when('client_type', {
    is: (value: PersonType) => value === PERSON_TYPE_ENUM.NATURAL,
    then: () =>
      yup
        .date()
        .transform((_, originalValue) => formatDateToValid(originalValue))
        .required('Insira a data de nascimento.')
        .typeError('Insira a data de nascimento.'),
    otherwise: () =>
      yup
        .date()
        .transform((_, originalValue) => formatDateToValid(originalValue))
        .required('Insira a data de fundação.')
        .typeError('Insira a data de fundação.'),
  }),
  client_document: yup.string().when('client_type', {
    is: (value: PersonType) => value === PERSON_TYPE_ENUM.NATURAL,
    then: () =>
      yup.string().required('Insira um CPF.').cpf('Insira um CPF válido.'),
    otherwise: () =>
      yup.string().required('Insira um CNPJ.').cnpj('Insira um CNPJ válido.'),
  }),
  shareholder_document: yup.string().when('client_type', {
    is: (value: PersonType) => value === PERSON_TYPE_ENUM.NATURAL,
    then: () => yup.string(),
    otherwise: () =>
      yup.string().required('Insira um CPF.').cpf('Insira um CPF válido.'),
  }),
  client_income: yup.number().when('client_type', {
    is: (value: PersonType) => value === PERSON_TYPE_ENUM.NATURAL,
    then: () =>
      yup
        .number()
        .min(1, 'Insira a renda mensal.')
        .typeError('Insira a renda mensal.')
        .required('Insira a renda mensal.'),
    otherwise: () =>
      yup
        .number()
        .min(1, 'Insira o faturamento anual.')
        .typeError('Insira o faturamento anual.')
        .required('Insira o faturamento anual.'),
  }),
  grace_days: yup.string().required('Selecione o tempo de carência.'),
  equipments_value: yup.number(),
  labor_value: yup.number(),
  financing_value: yup.string(),
})

const baseSchemaNaturalPerson = baseSchemaJuridicalPerson.concat(
  yup.object().shape({
    client_name: yup
      .string()
      .required('Insira o nome do solicitante.')
      .min(4, 'O campo precisa ter no mínimo 4 caracteres.')
      .fullName('Digite o nome completo.'),
  }),
)

export const schemaByPersonType = {
  [PERSON_TYPE_ENUM.NATURAL]: baseSchemaNaturalPerson,
  [PERSON_TYPE_ENUM.JURIDICAL]: baseSchemaJuridicalPerson,
}
